import { warn as logWarning } from '@hi3g-access/client-logger';
import { Grid } from '@3dk/3style';
import WidthWrapper from 'components/WidthWrapper';
import GRID_CONTAINER_TYPE from '../../../../../utils/propTypes/gridContainerType';
import { getFontColorFromBackgroundHEXColor } from './Colors/Colors';

const makeClasses = (props) => ({
  background: {
    backgroundColor: props.backgroundColorPicker,
    color: getFontColorFromBackgroundHEXColor(props.backgroundColorPicker) || 'inherit',
    backgroundImage: props.backgroundImage && `url(${props.backgroundImage?.url})`,
    padding: props.backgroundImage && `${props.contentPaddingVertical}px 0px`,
    backgroundSize: 'cover',
  },
  backgroundLargeDevices: (theme) => ({
    [theme.breakpoints.up('md')]: {
      backgroundImage: props.backgroundImageLargeDevices && `url(${props.backgroundImageLargeDevices?.url})`,
    },
  }),
  removeVerticalMargin: {
    marginTop: '0px',
    marginBottom: '0px',
  },
});

const getBreakPoints = (grid, index) =>
  grid.reduce(
    (result, gridItem) => ({
      ...result,
      [gridItem.size]: gridItem.display[index % gridItem.display.length].width,
      [`${gridItem.size}Offset`]: gridItem.display[index % gridItem.display.length].offset,
    }),
    {},
  );

const getDisplaySettings = (grid, index) =>
  grid.reduce(
    (result, gridItem) => ({
      ...result,
      [gridItem.size]: gridItem.display[index % gridItem.display.length].width === 0 ? 'none' : 'unset',
    }),
    {},
  );

const getAlignSelfSettings = (grid, index) =>
  grid.reduce(
    (result, gridItem) => ({
      ...result,
      [gridItem.size]: gridItem.display[index % gridItem.display.length].verticalCenter ? 'center' : 'auto',
    }),
    {},
  );

const getGridProps = (grid, index) => ({
  ...getBreakPoints(grid, index),
  alignSelf: getAlignSelfSettings(grid, index),
  display: getDisplaySettings(grid, index),
});

const GridContainer = (props) => {
  const {
    grid,
    useFullWidth,
    children,
    backgroundColorPicker,
    backgroundImage,
    backgroundImageLargeDevices,
    contentPaddingVertical,
    pageStyle,
  } = props;
  const validItems = grid?.every(
    (gridItem) =>
      gridItem.display.length &&
      gridItem.display.every((obj) => obj && typeof obj === 'object' && Object.keys(obj).includes('width', 'offset')),
  );
  if (!validItems || children?.length < 1) {
    logWarning({
      object: { grid, children },
      message: 'Blocks are missing required data',
    });
    return null;
  }

  const classes = makeClasses({
    backgroundColorPicker,
    backgroundImage,
    backgroundImageLargeDevices,
    contentPaddingVertical,
  });
  const gridStyling = [classes.background, backgroundImageLargeDevices && classes.backgroundLargeDevices, pageStyle];

  return (
    <div css={useFullWidth && gridStyling}>
      <WidthWrapper>
        {/* Content can never be full width, but the background might be */}
        <div css={!useFullWidth && gridStyling}>
          <Grid container columnSpacing={5} rowSpacing={0} rowGap={5} css={classes.removeVerticalMargin}>
            {children.map((child, index) => {
              const gridProps = getGridProps(grid, index);

              return (
                <Grid useBox {...gridProps} key={child.key ?? child.props?.children?.key}>
                  {child}
                </Grid>
              );
            })}
          </Grid>
        </div>
      </WidthWrapper>
    </div>
  );
};

GridContainer.propTypes = GRID_CONTAINER_TYPE;

GridContainer.defaultProps = {
  children: [],
  backgroundImage: null,
  backgroundImageLargeDevices: null,
  backgroundColorPicker: null,
  useFullWidth: false,
  contentPaddingVertical: 0,
  pageStyle: '',
};

export default GridContainer;
